<template>
  <div
    class="table-wrapper"
    :class="{ 'table-wrapper--overflow-visible': withYOverflow }"
  >
    <div
      class="table-wrapper__container"
      :class="{
        'table-wrapper__container--is-in-modal': isInModal,
        'table-wrapper__container--without-full-height-scroll':
          withoutFullHeightScroll,
        'table-wrapper__container--overflow-visible': withYOverflow
      }"
    >
      <table class="table">
        <ReflectTableHeader
          :columns="columns"
          :sorting-by-columns="sortingByColumns || {}"
          :with-vertical-borders="withVerticalBorders"
          @update-columns-sorting="updateColumnsSorting"
        />
        <tbody class="table__body">
          <slot />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'
import type { SortingByColumn } from '~/types/analytics'
import type { TableColumn } from '~/types/table'

export default {
  name: 'ReflectTable',
  props: {
    columns: {
      type: Array as PropType<TableColumn[]>,
      required: true
    },
    sortingByColumns: {
      type: Object as PropType<SortingByColumn | null>,
      default: null
    },
    withVerticalBorders: {
      type: Boolean,
      default: true
    },
    isInModal: {
      type: Boolean,
      default: false
    },
    withoutFullHeightScroll: {
      type: Boolean,
      default: false
    },
    withYOverflow: {
      type: Boolean,
      default: false
    }
  },
  slots: Object as SlotsType<{
    default: {}
  }>,
  emits: {
    'update-columns-sorting': (_: string) => {
      return true
    }
  },
  methods: {
    updateColumnsSorting(columnKey: string) {
      this.$emit('update-columns-sorting', columnKey)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 8px;
  height: 100%;

  &--overflow-visible {
    overflow: visible;
  }

  &__container {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: auto;

    &--overflow-visible {
      overflow: visible;
    }

    &--without-full-height-scroll {
      position: relative;
    }

    &--is-in-modal {
      position: relative;
      border: 1px solid $border-ternary;
      // Let space for the loader
      min-height: 80px;
      max-height: 360px;
      border-radius: 8px;
      box-sizing: border-box;
    }

    .table {
      border-collapse: separate;
      width: 100%;

      &__body {
        overflow: auto;

        & :deep(tr:nth-child(even)) {
          background-color: $bg-quaternary;
        }

        & :deep(tr:nth-child(odd)) {
          background-color: $bg-secondary;
        }
      }
    }
  }
}
</style>
